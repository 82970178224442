<!-- 打回公海设置 -->
<template>
  <div class="common-padding">
    <div class="title-container"><div>打回公海设置</div></div>
    <div class="si">
      线索私海客户
      <div class="common-btn-input-container">
        <el-input
          class="common-btn-input-input"
          type="number"
          placeholder="请输入"
          v-model="score"
          min="0"
          @keyup.native="proving"
        ></el-input>
        <div class="common-btn-input-btn" @click="requestBtn()">确 认</div>
      </div>
      天没有跟进客户信息客户将自动打回到线索公海
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { setHighSeaScore, highSeaScore } from "../../service/customer.js";
// import { Config } from "../../utils/index.js";
import { mapState } from "vuex";

export default {
  name: "returnSea",
  components: {},
  props: {},
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  data() {
    return {
      score: "",
      companyId: " "
    //   rolesCompany: this.$sto.get(Config.constants.userInfo).company_id // 所属公司
    };
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
      this.getComId()
    // this.getScore();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
      // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.companyId = this.comId
        }else{
          this.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.getScore();
      },400)
    },
    async getScore() {
      const { data } = await highSeaScore({ companyId: this.comId });
        this.score = data
    },
    async requestBtn(){
      let score = this.score;
      if (score) {
          const res =  await setHighSeaScore({ score: this.score,companyId: this.comId });
          if (res.code == 200){
            this.$message.success("设置成功");
          }
      } else {
        this.$message.error("请输入天数");
      }
    },
    proving() {
      this.score = this.score.replace(/[^\d]/g,'');
      this.score = this.score.replace('.','');
    },
  }
};
</script>

<style lang="less" scoped>
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
.si {
  margin: 24px 16px 24px 0;
  text-align: left;
  font-size: 14px;
  color: #101010;
}
</style>
